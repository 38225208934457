<template>
  <StrapiEvenementComponent
    v-if="itemActif"
    :item="itemActif"
    :items="autresItems"
    :expand="true"
  ></StrapiEvenementComponent>
</template>

<script>
import StrapiEvenementComponent from "../../components/strapi/StrapiEvenementComponent.vue";
import StrapiService from "../../services/strapi.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "StrapiEvenementView",
  components: { StrapiEvenementComponent },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    today() {
      return UtilsService.dateToIsoSqlDate(new Date());
    },
    itemActif() {
      return this.items.find((i) => i.id == this.$route.params.id);
    },
    autresItems() {
      return this.items
        .filter(
          (i) =>
            i.id !== this.itemActif.id &&
            (i.attributes.dateFin ?? i.attributes.dateDebut) >= this.today
        )
        .sort(
          UtilsService.sortBy(
            UtilsService.sortByStringProperty("attributes.dateDebut"),
            UtilsService.sortByStringProperty("attributes.dateFin")
          )
        );
    },
    collection() {
      return this.$store.getters["strapi/collection"]("evenements");
    },
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
  },
  mounted() {
    // Si l'utilisateur n'est pas autorisé, on quitte la page
    if (!this.$store.getters['auth/hasAccesStrapiActualites']) {
      this.$router.push({ name: 'accueil' });
    }
    this.load();
  },
  watch: {
    $route() {
      this.load();
    },
  },
  methods: {
    load() {
      this.items = [];
      return StrapiService.getCollection(
        this.collection,
        this.exploitationCourante.codeSociete
      ).then((response) => {
        this.items = this.collection.sort
          ? response.data.data.sort(this.collection.sort)
          : response.data.data;
      });
    },
  },
};
</script>
